<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
//import DatePicker from "vue2-datepicker";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { apiRequest } from "@/helpers/api-call";
import { format } from "date-fns";
import router from "@/router/index.js";
import Vue from "vue";


/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      title: "location sur RMobility",
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "liste",
          active: true,
        },
      ],
      tyMedia: [
        {
          name: "",
          value: 0,
        },
        {
          name: "Image",
          value: "image",
        },
        {
          name: "Vidéo",
          value: "video",
        },
      ],
      tyMessage: [
        {
          name: "Notification",
          value: 0,
        },
        {
          name: "Mail",
          value: 1,
        },
        {
          name: "Notification + Mail",
          value: 2,
        },
      ],
      userType: [
        {
          name: "CONDUCTEUR",
        },
        {
          name: "PARTICULER",
        },
        {
          name: "TAXIMAN",
        },
        {
          name: "SOCIETE",
        },
      ],
      url_link: "",
      userTypeSelect: "",
      messageSelect: null,
      mediaSelect: null,
      messageContent: [
        {
          sujet: "",
          message: "",
          userId: "",
        },
      ],
      value: null,
      value1: null,
      options: ["Sexe", "Taille", "Vehicule", "je vois"],
      idUser: 0,
      dataUser: [],
      dataUserD: [],
      nbreUser: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      


ordersData: [
        {
          orderid: "Toyota",
          date: "Camry XLE",
          billingname: "Hybride",
          total: "Orange",
          paymentstatus: "BX 6175",
        },
        {
          orderid: "Toyota",
          date: "Lexus RX450",
          billingname: "Hybride",
          total: "Bleu",
          paymentstatus: "BZ 0025",
        },
        {
          orderid: "MITSUBISHI",
          date: "caso 1",
          billingname: "Gasoil",
          total: "Rouge",
          paymentstatus: "BP 3695",
        },
        {
          orderid: "BMW",
          date: "X6",
          billingname: "Essence",
          total: "Marron",
          paymentstatus: "BY 5589",
        },
        {
          orderid: "Mercedes",
          date: "350",
          billingname: "Essence",
          total: "Noire",
          paymentstatus: "BG 2206",
        },
        {
          orderid: "BMW",
          date: "X1",
          billingname: "Essence",
          total: "Blanche",
          paymentstatus: "BF 8960",
        },
        {
          orderid: "BMW",
          date: "X2",
          billingname: "Hybride",
          total: "Jaune",
          paymentstatus: "BH 6808",
        },
        {
          orderid: "BMW",
          date: "X3",
          billingname: "Gasoil",
          total: "Verte",
          paymentstatus: "BE 6245",
        },
        {
          orderid: "RANGE ROVER",
          date: "R18",
          billingname: "Essence",
          total: "Grise",
          paymentstatus: "AL 6175",
          place:""
        },
      ],
      fields: [
        { key: "orderid", sortable: true, label: "Modèle" },
        /* { key: "date", sortable: true }, */
        { key: "date", sortable: true, label: "Marque" },
        { key: "total", sortable: true, label: "Couleur" },
        { key: "paymentstatus", label: "Immatriculation" },
        { key: "invoice", sortable: true, label: "Année de circulation" },
        { key: "place", sortable: true, label: "Lieu" },
        { key: "billingname", sortable: true, label: "Motorisation" },
        { key: "action", label: "Actions" },
      ],
      showDisable: false,
      showDelete: false,
      showEdit: false,
      showSucess: false,
      showEchec: false,
      showSucessEdit: false,
      showEchecEdit: false,
      showEditR:false,
      userToUse: 0,
      infoUser: {
        id: "",
        firstname: "",
        lastname: "",
        profession: "",
        birthday: "",
        adresse: "",
        ville: "",
        pays: "",
        userType: "",
        tel: "",
        email: "",
        personneUrgence: "",
        contactUrgence: "",
        name: "",
        boitePostal: "",
        apropos: "",
        permisCategorie: "",
        permisAnnee: "",
        permisNumuero: "",
      },
      showMessage: false,
      showMessageMail: false,
      editor: ClassicEditor,
      editorData: "",
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.dataUser.length;
    },
  },
  async mounted() {
    this.init();
  },
  methods: {
    async init() {
      //Chargement des données
      const data = await apiRequest("GET", "admin/users", undefined, false);
      if (data && data.data) {
        //console.log("data.data:", data.data);
        const formattedTable = data.data.map((user) => {
          var nom = user.lastname == "" ? "Nom" : user.lastname;
          var prenom = user.firstname == "" ? "Prénom" : user.firstname;
          return {
            id: user.id,
            username: nom + " " + prenom,
            firstname: user.firstname,
            lastname: user.lastname,
            pays: user.pays,
            tel: user.tel,
            trajet: user.nombreTrajet,
            dateIns: new Date(user.dateCreation).toLocaleString("fr-FR"),
            badge: user.userValidated,
            statut: user.active ? "Actif" : "Suspendu",
          };
        });
        this.dataUser = formattedTable;
        console.log("data", data.data);
      }

      const dataD = await apiRequest(
        "GET",
        "admin/users-inactif",
        undefined,
        false
      );
      if (dataD && dataD.data) {
        const formattedTableD = dataD.data.map((user) => {
          return {
            id: user.id,
            username:
              user.lastname[0] == undefined
                ? "Nom Indéfini"
                : user.lastname[0] + ". " + user.firstname == undefined
                ? "Prénom Indéfini"
                : user.firstname,
            firstname: user.firstname,
            lastname: user.lastname,
            tel: user.tel,
            trajet: user.nombreTrajet,
            dateIns: new Date(user.dateCreation).toLocaleString("fr-FR"),
            badge: user.userValidated,
            statut: user.active ? "Actif" : "Suspendu",
          };
        });

        this.dataUserD = formattedTableD;

        // Set the initial number of items
        this.totalRows = this.dataUser.length;
        this.nbreUser = this.dataUser.length;
      }
    },
    async addMessage() {
      this.showMessage = false;
      this.showMessageMail = false;
      const data = await apiRequest(
        "POST",
        "admin/message-user",
        {
          idUser: this.messageContent.userId,
          typeMessage: this.messageSelect.value,
          subject: this.messageContent.sujet,
          text: this.messageContent.message,
          media_type: this.tyMedia.value ? this.tyMedia.value : "",
          url: this.url_link,
        },
        false
      );
      //console.log("data.data:", data);
      if (data && data.data) {
        this.showSucess = true;
        this.init();
        console.log(data);
      } else {
        this.showEchec = true;
        this.init();
        console.log(data);
      }
    },
    async ModifSubmit() {
      this.showEdit = false;
      /* if (this.infoUser.userType.name && this.infoUser.userType.name.data) {
        this.userTypeSelect = this.infoUser.userType.name;
      } */
      const Editdata = await apiRequest(
        "PUT",
        "users/" + this.infoUser.id,
        {
          idUser: this.infoUser.id,
          lastname: this.infoUser.lastname,
          firstname: this.infoUser.firstname,
          tel: this.infoUser.telephone,
          email: this.infoUser.email,
          adresse: this.infoUser.adresse,
          ville: this.infoUser.ville,
          pays: this.infoUser.pays,
          name: this.infoUser.name,
          boitePostal: this.infoUser.boitePostal,
          birthday: this.infoUser.birthday,
          userType: this.userTypeSelect,
          profession: this.infoUser.profession,
          apropos: this.infoUser.apropos,
          permisCategorie: this.infoUser.permisCategorie,
          permisAnnee: this.infoUser.permisNumuero,
          permisNumuero: this.infoUser.permisNumuero,
          personneUrgence: this.infoUser.contactUrgence,
          contactUrgence: this.infoUser.contactUrgence,
        },
        false
      );

      if (Editdata && Editdata.data) {
        this.showSucessEdit = true;
        this.init();
        //console.log(Editdata);
      } else {
        this.showEchecEdit = true;
        this.init();
        //console.log(Editdata);
      }

      console.log(this.infoUser.id);
    },
    EditModal() {
      this.submitted = false;
      this.showEdit = false;
    },

    async editClickR(){
      this.showEditR = true;
    },
    async editClick(row) {
      this.showEdit = true;
      this.infoUser.id = row.id;
      /* this.infoUser.lastname = row.lastname;
      this.infoUser.firstname = row.firstname;
      this.infoUser.profession = row.profession;
      this.infoUser.adresse = row.adresse;
      this.infoUser.ville = row.ville;
      this.infoUser.pays = row.pays;
      this.infoUser.email = row.email;
      this.infoUser.contactUrgence = row.contactUrgence;
      this.infoUser.personneUrgence = row.personneUrgence;
      this.infoUser.telephone = row.tel;
      this.infoUser.userTypeSelect = row.userType */

      const dataDetail = await apiRequest(
        "POST",
        "admin/detail-user",
        { id: row.id },
        false
      );

      if (dataDetail && dataDetail.data) {
        //console.log("Detail User", dataDetail.data)
        this.infoUser.lastname = dataDetail.data.personne.lastname;
        this.infoUser.firstname = dataDetail.data.personne.firstname;
        this.infoUser.profession = dataDetail.data.personne.profession;
        (this.infoUser.birthday = format(
          new Date(dataDetail.data.personne.birthday),
          "dd/MM/yyyy hh:mm:ss"
        )),
          (this.infoUser.adresse = dataDetail.data.personne.adresse);
        this.infoUser.ville = dataDetail.data.personne.ville;
        this.infoUser.pays = dataDetail.data.personne.pays;
        this.infoUser.email = dataDetail.data.personne.email;
        this.infoUser.contactUrgence = dataDetail.data.personne.contactUrgence;
        this.infoUser.personneUrgence =
          dataDetail.data.personne.personneUrgence;
        this.infoUser.telephone = dataDetail.data.personne.tel;
        this.infoUser.userTypeSelect = dataDetail.data.personne.userType;
      }

      console.log("type ", this.infoUser.userTypeSelect);
      console.log("row ", row);
    },
    desactiverUser(row) {
      this.userToUse = row.id;
      //console.log('userToDel:', this.userToUse)
      this.showDelete = true;
    },
    async desactiveUser() {
      this.showDelete = false;
      const data = await apiRequest(
        "POST",
        "admin/suspendre-user",
        { idUser: this.userToUse },
        false
      );
      //console.log("data.data:", data);
      if (data && data.data) {
        this.position();
        this.init();
      }
    },
    messageShow(row, typeMessage) {
      this.messageSelect = typeMessage;
      //console.log('typeMessage:', typeMessage)
      if (typeMessage.value == 0) this.showMessage = true;
      if (typeMessage.value == 1) this.showMessageMail = true;
      this.messageContent.userId = row.id;
      //console.log(row.id);
    },
    async activeUser(row) {
      this.userToUse = row.id;
      const data = await apiRequest(
        "POST",
        "admin/activer-user",
        { idUser: this.userToUse },
        false
      );
      //console.log("data.data:", data);
      if (data && data.data) {
        this.position();
        this.init();
      }
    },
    position() {
      Vue.swal({
        position: "center-end",
        icon: "success",
        title: "Mise à jour effectuée",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    goToDetail(row) {
      console.log("row:", row);
      router.push(`/vehicule/details/${row.id}`);
      //router.push('/user/user_info/'+row.id)
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Liste des véhicules à louer</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <!-- <label class="d-inline-flex align-items-center">
                        Choisir
                    <multiselect
                    v-model="value1"
                    :options="options"
                    :multiple="true"
                    :searchable="false" class="col-md-12"
                    ></multiselect>
                    </label> -->
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-3">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    style="cursor: pointer"
                    class="table-centered"
                    :items="ordersData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="goToDetail"
                  >
                    <template v-slot:cell(badge)="row">
                      <div
                        class="py-2 d-flex justify-content-center"
                        :class="{
                          'fa fa-star': `${row.value}` === 'false',
                          'fa fa-star badge-soft-warning':
                            `${row.value}` === 'true',
                        }"
                      ></div>
                    </template>
                    <template v-slot:cell(statut)="row">
                      <div
                        class="py-2 d-flex justify-content-center badge font-size-12"
                        :class="{
                          'badge-soft-danger': `${row.value}` === 'Suspendu',
                          'badge-soft-success': `${row.value}` === 'Actif',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(action)="row">
                     <!--  <a
                        href="javascript:void(0);"
                        class="text-success"
                        v-b-tooltip.hover
                        @click="editClick(row.item)"
                        title="Louer"
                      >
                        <i class="mdi mdi-check font-size-18"></i>
                      </a> -->
                      <button class="btn btn-success" @click="editClick(row.item)">Louer</button>
                      <!-- <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        @click="desactiverUser(row.item)"
                        title="Supprimer"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a> -->
                     
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Liste des véhicules loués</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <!-- <label class="d-inline-flex align-items-center">
                        Choisir
                    <multiselect
                    v-model="value1"
                    :options="options"
                    :multiple="true"
                    :searchable="false" class="col-md-12"
                    ></multiselect>
                    </label> -->
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-3">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    style="cursor: pointer"
                    class="table-centered"
                    :items="ordersData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="goToDetail"
                  >
                    <template v-slot:cell(badge)="row">
                      <div
                        class="py-2 d-flex justify-content-center"
                        :class="{
                          'fa fa-star': `${row.value}` === 'false',
                          'fa fa-star badge-soft-warning':
                            `${row.value}` === 'true',
                        }"
                      ></div>
                    </template>
                    <template v-slot:cell(statut)="row">
                      <div
                        class="py-2 d-flex justify-content-center badge font-size-12"
                        :class="{
                          'badge-soft-danger': `${row.value}` === 'Suspendu',
                          'badge-soft-success': `${row.value}` === 'Actif',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(action)="row">
                      <!-- <a
                        href="javascript:void(0);"
                        class="text-success"
                        v-b-tooltip.hover
                        @click="editClickR(row.item)"
                        title="retourner"
                      >
                        <i class="mdi mdi-check font-size-18"></i>
                      </a> -->
                      <button class="btn btn-danger" @click="editClickR(row.item)">Retourner</button>
                      <!-- <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        @click="desactiverUser(row.item)"
                        title="Supprimer"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a> -->
                     
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showDelete"
      title="Voulez-vous vraiment supprimer ce véhicule ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDelete = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="desactiveUser"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showEchec"
      title="L'envoie a échoué"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchec = false"
          >Reéssayez</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showSucessEdit"
      title="Utilisateur modifié avec succès !!!"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="success" @click="showSucessEdit = false"
          >OK</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showEchecEdit"
      title="La modification a échouée"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchecEdit = false"
          >Reéssayez</b-button
        >
      </div>
    </b-modal>

<b-modal
      id="modal-center"
      centered
      size="xl"
      v-model="showEdit"
      title="Louer un véhicule"
      title-class="font-18"
      hide-footer
    >
      <form
        class="form-horizontal"
        role="form"
        method="post"
        @submit.prevent="EditSubmit"
      >
        <div class="row">
          <div class="col-md-6">
            <label for="">Nom </label>
            <input type="text" class="form-control" v-model="marque" id="" />
          </div>
          <div class="col-md-6">
            <label for="">Prénom</label>
            <input type="text" class="form-control" v-model="model" id="" />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <label for="">Numéro de téléphone</label>
            <input type="text" class="form-control" v-model="carte" id="" />
          </div>
          <div class="col-md-6">
            <label for="">Photo d'identité</label>
            <input type="file" class="form-control" id="" />
          </div>
          <div class="col-md-6 mt-4">
            <label for="">Date de location</label>
            <input type="text" class="form-control" v-model="annee" id="" />
          </div>
          <div class="col-md-6 mt-4">
            <label for="">Date prévue de retour</label>
            <input
              type="text"
              class="form-control"
              v-model="motorisation"
              id=""
            />
        </div>
          <div class="col-md-6 mt-4">
            <label for="">Coût de la location</label>
            <input
              type="text"
              class="form-control"
              v-model="immatriculation"
              id=""
            />
          </div>
        </div>
        <button class="btn btn-success float-right mt-4" type="submit">
          Valider
        </button>
      </form>
    </b-modal>

    <b-modal
      id="modal-center"
      centered
      size="xl"
      v-model="showEditR"
      title="Retourner un véhicule"
      title-class="font-18"
      hide-footer
    >
      <form
        class="form-horizontal"
        role="form"
        method="post"
        @submit.prevent="EditSubmit"
      >
        <div class="row">
          <div class="col-md-6">
            <label for="">Nom </label>
            <input type="text" class="form-control" v-model="marque" id="" />
          </div>
          <div class="col-md-6">
            <label for="">Prénom</label>
            <input type="text" class="form-control" v-model="model" id="" />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <label for="">Date location</label>
            <input type="text" class="form-control" v-model="carte" id="" />
          </div>
          <div class="col-md-6">
            <label for="">Date retour location</label>
            <input type="text" class="form-control" v-model="annee" id="" />
          </div>
        </div>
        <div class="row mt-4">
          <!-- <div class="col-md-6">
            <label for="">Motorisation</label>
            <input
              type="text"
              class="form-control"
              v-model="motorisation"
              id=""
            />
          </div>
          <div class="col-md-6">
            <label for="">Immatriculation</label>
            <input
              type="text"
              class="form-control"
              v-model="immatriculation"
              id=""
            />
          </div> -->
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <label for="">Couleur</label>
            <input type="text" class="form-control" v-model="couleur" id="" />
          </div>
          <div class="col-md-6">
            <label for="">Photo véhicule</label>
            <input type="file" class="form-control" id="" />
          </div>
        </div>
        <button class="btn btn-success float-right mt-4" type="submit">
          Modifier
        </button>
      </form>
    </b-modal>

    <!-- ENVOYER LE MESSAGE NOTIF-->
    <b-modal v-model="showMessage" title="Nouveau Message" centered>
      <form>
        <div class="form-group">
          <label class="control-label">Choisir le type de message:</label>
          <multiselect
            v-model="messageSelect"
            :options="tyMessage"
            label="name"
            track-by="value"
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Sujet"
            v-model="messageContent.sujet"
          />
        </div>
        <div class="form-group mb-2">
          <!--  <ckeditor
            v-model="messageContent.message"
            :editor="editor"
            aria-placeholder="Votre Message"
          ></ckeditor> -->
          <textarea
            v-model="messageContent.message"
            placeholder="Votre message"
            class="form-control"
            id=""
            cols="30"
            rows="10"
          ></textarea>
        </div>
        <div class="form-group">
          <multiselect
            v-model="mediaSelect"
            :options="tyMedia"
            placeholder="Image, vidéo"
            label="name"
            track-by="value"
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            v-model="url_link"
            placeholder="Lien du média"
          />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showMessage = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="addMessage">
          Envoyer
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <!-- -->

    <!-- ENVOYER LE MESSAGE MAIL-->
    <b-modal v-model="showMessageMail" title="Nouveau Message" centered>
      <form>
        <div class="form-group">
          <label class="control-label">Choisir le type de message:</label>
          <multiselect
            v-model="messageSelect"
            :options="tyMessage"
            label="name"
            track-by="value"
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Sujet"
            v-model="messageContent.sujet"
          />
        </div>
        <div class="form-group mb-2">
          <ckeditor
            v-model="messageContent.message"
            :editor="editor"
            aria-placeholder="Votre Message"
          ></ckeditor>
        </div>
        <div class="form-group">
          <multiselect
            v-model="mediaSelect"
            :options="tyMedia"
            placeholder="Image, vidéo"
            label="name"
            track-by="value"
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            v-model="url_link"
            placeholder="Lien du média"
          />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showMessage = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="addMessage">
          Envoyer
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <!-- -->
  </Layout>
</template>
